<template>

<div>

	<app-input-text label="Account Number" v-model="model.account_number" :validation="$v.model.account_number" placeholder="Enter account number" :maxlength="32" />
	<app-input-text label="Routing Number" v-model="model.routing_number" :validation="$v.model.routing_number" placeholder="Enter routing number" :maxlength="32" />

	<div class="buttons">

		<app-button text="Confirm" :disabled="$v.$invalid" :loading="is.saving" v-on:click="onConfirmClick" />
		<app-button text="Cancel" theme="plain" v-on:click="onCancelClick" />

	</div>

</div>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			standalone: true,
			model: {
				account_number: '',
				routing_number: ''
			}
		}

	},

	validations: {
		model: {
			routing_number: {
				required,
				maxLength: maxLength(64)
			},
			account_number: {
				required,
				maxLength: maxLength(64)
			}
		}
	},

	methods: {

		onCancelClick: function() {

			this.$emit('cancelled')

		},

		onConfirmClick: async function() {

			this.is.saving = true

			this.$api.post('organisation/billing/settings/bank', this.model).then(function() {

				this.$notify({
					message: ['You have successfully connected your bank account.']
				})

				this.$emit('completed')

			}.bind(this), function() {

				this.is.saving = false

				this.$notify({
					message: ['Sorry, your bank account could not be connected. Please check your details and try again.'],
					type: 'error'
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.buttons {
	display: flex;
	justify-content: center;
}

.buttons >>> .button {
	margin: 0px 10px;
}

</style>